import React from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  Subtitle,
  InnerWrapper,
  mainWhite,
  darkBlue,
  lightBlue,
  screen,
  Button,
} from "../components/common/variables";

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 90px;
  color: ${darkBlue};
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 160px;
    padding-bottom: 190px;
  }

  .title {
    line-height: 1.3;

    span {
      display: block;
      font-weight: 400;
      margin: 2px 0 0 0;
    }
  }

  .description {
    font-size: 1rem;
    max-width: 640px;
    margin: 15px 0 35px 0;
    @media ${screen.medium} {
      margin: 25px 0 50px 0;
    }
    @media ${screen.xLarge} {
      font-size: 1.1rem;
    }
  }

  form {
    display: block;
    max-width: 680px;

    label {
      display: none;
    }

    .row {
      display: flex;
      width: 100%;
      margin: 0 0 16px 0;
      input {
        color: ${darkBlue};
        width: 100%;
        display: block;
        font-size: 1.1rem;
        padding: 12px 15px;
        border-radius: 10px;
        border: 1px solid ${darkBlue};
        font-weight: 400;
        outline-color: ${lightBlue};

        &::placeholder {
          font-weight: 300;
        }
      }

      .col {
        width: 50%;

        &--first-name,
        &--city {
          margin: 0 16px 0 0;
        }
      }
    }

    .submit {
      display: flex;
      justify-content: flex-end;
      margin: 30px 0 0 0;
      @media ${screen.medium} {
        margin: 0;
      }
      button {
      }
    }
  }
`;
const RegisterPage = () => {
  return (
    <Layout>
      <SEO
        title="Registration - Client-Centric Advice Program (Enterprise)"
        description="This program requires a minimum of 12 participants."
      />
      <Wrapper>
        <InnerWrapper>
          <Subtitle className="title" dark>
            Client-Centric Advice Program (Enterprise) <span>Registration</span>
          </Subtitle>

          <p className="description">
            This program requires a minimum of 12 participants. When this number
            has been reached we’ll be in touch to organise payment and
            commencement date.
          </p>
          <div>
            <form
              className="js-cm-form"
              id="subForm"
              action="https://www.createsend.com/t/subscribeerror?description="
              method="post"
              data-id="2BE4EF332AA2E32596E38B640E905619A163E6DF8CCAC33837B284FEBC66380688FC5CF09C045F28B049D862FFFA71A390C639916780263087F345EE91643557"
            >
              <div>
                <div className="row">
                  <div className="col col--first-name">
                    <label>First Name </label>

                    <input
                      aria-label="First Name"
                      id="fieldjrikdrk"
                      maxLength={200}
                      name="cm-f-jrikdrk"
                      required
                      placeholder="First name"
                    />
                  </div>
                  <div className="col">
                    <label>Last Name </label>

                    <input
                      aria-label="Last Name"
                      id="fieldjrikdru"
                      maxLength={200}
                      name="cm-f-jrikdru"
                      required
                      placeholder="Last name"
                    />
                  </div>
                </div>

                <div className="row">
                  <label>Email </label>

                  <input
                    autoComplete="Email"
                    aria-label="Email"
                    className="js-cm-email-input qa-input-email"
                    id="fieldEmail"
                    maxLength={200}
                    name="cm-ydddduy-ydddduy"
                    required
                    type="email"
                    placeholder="Email address"
                  />
                </div>
                <div className="row">
                  <label>Phone Number </label>

                  <input
                    aria-label="Phone Number"
                    id="fieldjrikdyl"
                    maxLength={200}
                    name="cm-f-jrikdyl"
                    required
                    placeholder="Phone number"
                  />
                </div>
                <div className="row">
                  <label>Business Name </label>

                  <input
                    aria-label="Business Name"
                    id="fieldjrikdyr"
                    maxLength={200}
                    name="cm-f-jrikdyr"
                    required
                    placeholder="Business name"
                  />
                </div>

                <div className="row">
                  <div className="col col--city">
                    <label>City </label>

                    <input
                      aria-label="City"
                      id="fieldjrikdyy"
                      maxLength={200}
                      name="cm-f-jrikdyy"
                      required
                      placeholder="City"
                    />
                  </div>

                  <div className="col">
                    <label>Country </label>
                    <span>
                      <input
                        aria-label="Country"
                        id="fieldjrikdyj"
                        maxLength={200}
                        name="cm-f-jrikdyj"
                        required
                        placeholder="Country"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="submit">
                <Button type="submit">REGISTER NOW</Button>
              </div>
            </form>
          </div>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default RegisterPage;
